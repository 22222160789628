.cash-flow-table{
  .ant-table-row {
    &.summary-table {
      background: @background-color-base;
      .cash-flow-current-month-column{
        &:not(.ant-table-cell-row-hover){
        background: @background-color-base;
      }
      }
    }

    .ant-table-cell-fix-left{
      background: @background-color-base;
    }
  }

  .cash-flow-current-month-column{
    background: @background-color-light;
    border-left: @border-color-split 1px solid;
    border-right: @border-color-split 1px solid;
  }

  .ant-table-thead{
    .ant-table-cell{
      text-align: center !important;
    }
  }
}