.page-content-background-logo{
  opacity: 0.15;
  min-height: 130%;
  min-width: 130%;
  max-width: 180%;
  max-height: 180%;
  left: unset;
  right: 45px;
  transform: translate(50%, -50%);
}
