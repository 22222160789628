.ant-tabs-tab.ant-tabs-tab-active {
  .ant-tabs-tab-btn {
    text-shadow: none;
  }
}

.ant-tabs-tab {
/*  background: @dark-100 !important;
  .ant-tabs-tab-btn{
    color: @primary-2 !important;
    &:hover{
      color: @dark-0 !important;
    }
  }*/
  &.ant-tabs-tab-active {
    background: @b-20 !important;
    border-bottom-color: @b-20 !important;
    .ant-tabs-tab-btn{
     // color: @dark-0 !important;
    }
  }
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  border-color: @b-60;
}

.ant-tabs-top > .ant-tabs-nav::before {
  border-color: @b-60;
}